import axios from "axios";

type accountInsertType = { email: string; pwd: string; name: string };
const accountInsert = (
	param: accountInsertType,
	success: VoidFunction,
	failture: VoidFunction,
) => {
	axios
		.post(
			process.env.REACT_APP_SERVER_ORIGIN + "/account-insert",
			param,
		)
		.then(function (response) {
			console.log(response);
			success();
		})
		.catch(function (error) {
			console.log(error);
			failture();
		});
};
export { accountInsert };
