import axios from "axios";

const fakeAuthProvider = {
	isAuthenticated: false,
	signin(
		email: string,
		pwd: string,
		success: VoidFunction,
		failture: VoidFunction,
	) {
		axios
			.post(
				process.env.REACT_APP_SERVER_ORIGIN + "/login",
				{ email: email, pwd: pwd },
			)
			.then(function (response) {
				console.log(response);
				fakeAuthProvider.isAuthenticated = true;
				success();
			})
			.catch(function (error) {
				console.log(error);
				failture();
			});
	},
	signout(callback: VoidFunction) {
		fakeAuthProvider.isAuthenticated = false;
		callback();
	},
};

export { fakeAuthProvider };
