import * as React from "react";
import { Routes, Route, Link, useNavigate, useLocation, Navigate, Outlet, } from "react-router-dom";
import { fakeAuthProvider } from "./auth";
import { accountInsert } from "./api";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<TopPage />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route element={<RequireAuth />}>
          <Route path="/register" element={<>ログ記録</>} />
          <Route path="/review" element={<>ログ確認(PC)</>} />
          <Route path="/account" element={<>アカウント</>} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function RequireAuth() {
  let auth = useAuth();
  let location = useLocation();
  if (!auth.user) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  return (LayOut());
}

function LayOut() {
  let auth = useAuth();
  let navigate = useNavigate();
  return (
    <div>
      <header>
        <h1>ちょいログ-ログ記録</h1>
      </header>
      <nav>
        <ul>
          <li>
            <Link to="/register">ログ記録</Link>
          </li>
          <li>
            <Link to="/review">ログページ(PC)</Link>
          </li>
          <li>
            <Link to="/account">{auth.user}!{" "}様</Link>
          </li>
          <li>
            <button onClick={() => { auth.signout(() => navigate("/")); }}>
              サインアウト
            </button>
          </li>
        </ul>
      </nav>
      <Outlet />
    </div>
  );
}

function waiting(isWaiting: boolean) {
  let body = document.querySelector('body') as HTMLElement;
  if (isWaiting) {
    body.classList.add('cursor');
  } else {
    body.classList.remove('cursor');
  }
}

interface AuthContextType {
  user: any;
  signin: (email: string, pwd: string, success: VoidFunction, failture: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}
let AuthContext = React.createContext<AuthContextType>(null!);
function useAuth() {
  return React.useContext(AuthContext);
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);

  let signin = (email: string, pwd: string, success: VoidFunction, failture: VoidFunction) => {
    waiting(true);
    return fakeAuthProvider.signin(email, pwd, () => {
      waiting(false);
      console.log('signin-after-success')
      setUser(email);
      success();
    }, () => {
      waiting(false);
      console.log('signin-after-failture')
      setUser(email);
      failture();
    });
  };

  let signout = (callback: VoidFunction) => {
    waiting(true);
    return fakeAuthProvider.signout(() => {
      waiting(false);
      console.log('signout-after')
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function TopPage() {
  return (
    <div>
      <header>
        <h1>ちょいログ-概要ページ</h1>
      </header>
      <p>
        特徴：
      </p>
      <p>
        簡単に行動の記録をつけれます。スマホでも使えるようにしておりますが、PC向けです。
      </p>
      <p>
        <Link to="/signin">ログイン</Link>&nbsp;&nbsp;&nbsp;
        <Link to="/signup">アカウント新規登録</Link>
      </p>
    </div>
  );
}

function SignupPage() {
  const navigate = useNavigate();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    let email = formData.get("email") as string;
    let pwd = formData.get("pwd") as string;
    let name = formData.get("name") as string;
    waiting(true);
    accountInsert({
      email: email, pwd: pwd, name: name
    }, () => {
      waiting(false);
      alert('アカウント登録成功');
      navigate("/signin", { replace: true });
    }, () => {
      waiting(false);
      alert('アカウント登録失敗');
    });
  }
  return (
    <div>
      <header>
        <h1>ちょいログ-サインアップページ</h1>
      </header>
      <button onClick={() => navigate(-1)}>戻る</button>
      <form onSubmit={handleSubmit}>
        <label>メールアドレス: <input name="email" type="text" /></label>
        <br /><label>パスワード: <input name="pwd" type="password" /></label>
        <br /><label>お名前: <input name="name" type="text" /></label>
        <br /><button type="submit">登録</button>
      </form>
    </div>
  );
}

function SigninPage() {
  let navigate = useNavigate();
  let auth = useAuth();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    let email = formData.get("email") as string;
    let pwd = formData.get("pwd") as string;

    auth.signin(email, pwd, () => {
      navigate("/register", { replace: true });
    }, () => {
      alert('ログインできませんでした。')
    });
  }

  return (
    <div>
      <p>ログインページ</p>
      <form onSubmit={handleSubmit}>
        <label>メールアドレス: <input name="email" type="text" /></label>{" "}
        <label>パスワード: <input name="pwd" type="password" /></label>{" "}
        <button type="submit">Login</button>
      </form>
    </div>
  );
}